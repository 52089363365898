import { Route, Routes } from "react-router-dom";
import { paths } from "../utils/routes";
import Vendors from "../pages/vendors/Index";
import VendorsLists from "../pages/vendors/List";

function VendorsRouter(props) {
  return (
    <Routes>
      <Route path={paths.base} element={<Vendors />} />
      <Route path={paths.list} element={<VendorsLists />} />
    </Routes>
  );
}

export default VendorsRouter;
