import { useEffect, useState } from "react";
import useBusinesses from "../../hooks/api/useBusinesses";
import Table from "../../components/table/Table";
import { addDaysToDate, currencyFormatter, formatDate } from "../../utils/func";
import moment from "moment";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "../../components/Select";

const Card = ({ title, value }) => (
  <div className="card acct-info flex justify-center align-center flex-column">
    <span className="q f400 t-default">{title}</span>
    <br />
    <h2 className="ttitle montserrat t-blue f600">{value}</h2>
  </div>
);

const formatDate02 = (date = new Date()) => {
  return moment(date).format("YYYY-MM-DD");
};

function Sales(props) {
  const start = new Date();
  start.setFullYear(2023);
  start.setMonth(0, 1);
  const [endDate, setEndDate] = useState(
    formatDate02(addDaysToDate(new Date(), 730))
  );
  const [startDate, setStartDate] = useState(formatDate02(start));
  const [kiosk, setKiosk] = useState(null);
  const {
    getSales,
    sales: salesRecord,
    isLoading,
    getKiosks,
    kiosks,
  } = useBusinesses();
  const sales = kiosk
    ? salesRecord.filter((_) => _.kiosk.id === kiosk)
    : salesRecord;

  useEffect(() => {
    getSales(startDate, endDate);
    getKiosks();
  }, []);

  return (
    <div>
      <br />
      <br />
      <div className="flex range-picker justify-evenly">
        <Input
          placeholder="From"
          type={"date"}
          style={{ padding: 10, fontSize: 14 }}
          value={startDate}
          onChange={setStartDate}
        />
        <Input
          placeholder="To"
          type={"date"}
          style={{ padding: 10, fontSize: 14 }}
          value={endDate}
          onChange={setEndDate}
        />
        <Select
          placeholder="iCart"
          initialValue={kiosk}
          options={kiosks?.map((_) => ({
            value: _.id,
            label: `${_.id}(${_.location.label})`,
          }))}
          onSelect={setKiosk}
        />
        <Button
          loading={isLoading}
          disabled={isLoading}
          title={"Refresh Sales"}
          onClick={() => getSales(startDate, endDate)}
          className={"p-20"}
        />
      </div>
      <div className="flex justify-between align-center gap-3">
        <Card
          title="Total Sales"
          value={sales.reduce((p, c) => p + c.quantity, 0)}
        />
        <Card
          title="Cost of Sales"
          value={currencyFormatter(
            sales.reduce((p, c) => p + c.costOfSales, 0).toFixed(0)
          )}
        />
        <Card
          title="Creators"
          value={currencyFormatter(
            sales.reduce((p, c) => p + c.creator, 0).toFixed(0)
          )}
        />
        <Card
          title="Subscribers Profit"
          value={currencyFormatter(
            sales.reduce((p, c) => p + c.subscriberProfit, 0).toFixed(0)
          )}
        />
        <Card
          title="iCart Revenue"
          value={currencyFormatter(
            sales.reduce((p, c) => p + c.adminProfit, 0).toFixed(0)
          )}
        />
        <Card
          title="Total Revenue"
          value={currencyFormatter(
            sales
              .reduce((p, c) => p + c.quantity * c.priceCapture, 0)
              .toFixed(0)
          )}
        />
        <Card
          title="VAT"
          value={currencyFormatter(
            sales.reduce((p, c) => p + c.vat, 0).toFixed(0)
          )}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <Table loading={isLoading} title="Sales" head={head} data={sales} />
    </div>
  );
}

export default Sales;

const head = [
  {
    title: "#",
    target: "#",
    className: "count",
  },
  {
    title: "Item",
    target: "menu.name",
  },
  {
    title: "Quantity",
    target: "quantity",
  },
  {
    title: "iCart",
    target: "kiosk.id",
  },
  {
    title: "Price",
    target: "priceCapture",
    render: currencyFormatter,
  },
  {
    title: "Owner",
    target: "subscriberProfit",
    render: currencyFormatter,
  },
  {
    title: "Total",
    target: ["priceCapture", "quantity"],
    render: (v) => currencyFormatter(v[0] * v[1]),
  },
  {
    title: "Date",
    target: "createdAt",
    render: formatDate,
  },
];
