import React, { useEffect, useState } from "react";

import moment from "moment";
import useBusinesses from "../../hooks/api/useBusinesses";
import Card2 from "../../components/Card2";
import Table from "../../components/table/Table";
import AccountPreview from "../../components/AccountPreview";
import svg from "../../utils/svg";
import { links } from "../../utils/routes";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Select from "../../components/Select";
import { preOrder, preOrdersFilter as filters } from "../../utils/vars";
import Input from "../../components/Input";
import { formatDate as formatDate02 } from "../../utils/func";

const formatDate = (date = new Date()) => {
  return moment(date).format("YYYY-MM-DD");
};

function Vendors(props) {
  const start = new Date().setMonth(0, 1);
  const { getVendors, vendors, isLoading } = useBusinesses();
  const [endDate, setEndDate] = useState(
    formatDate(new Date().setFullYear(2026))
  );
  const [startDate, setStartDate] = useState(formatDate(start));
  const [filter, setFilter] = useState(preOrder.pending);
  const navigate = useNavigate();
  const head = [
    {
      title: "#",
      target: "#",
      className: "count",
    },
    {
      title: "User",
      target: ["profileImage", "firstName", "lastName"],
      render: (value) => (
        <AccountPreview
          user={{
            profileImage: value[0],
            firstName: value[1],
            lastName: value[2],
          }}
        />
      ),
    },
    {
      title: "Email",
      target: "email",
      render: (v) => <span style={{ textAlign: "left" }}>{v}</span>,
    },
    // {
    //   title: "Status",
    //   target: "status",
    //   render: (value) => <span className={`status ${value}`}>{value}</span>,
    // },
    {
      title: "Phone Number",
      target: "phoneNumber",
      render: (v) => v,
    },

    {
      title: "Country",
      target: "country",
      render: (v) => v,
    },
    {
      title: "City",
      target: "city",
      render: (v) => v,
    },
    {
      title: "Is Paid",
      target: "isPaid",
      render: (v) => (
        <span className={`status ${v?.toString()}`}>
          {v?.toString()?.toUpperCase()}
        </span>
      ),
    },
    {
      title: "Date",
      target: "createdAt",
      render: formatDate02,
    },
    // {
    //   title: "Actions",
    //   target: "_id",
    //   render: (id) => (
    //     <button
    //       className="action flex align-center"
    //       onClick={() => {
    //         // navigate(links.businesses.view(id), {
    //         //   state: { data: vendors.filter((a) => a._id == id)[0] },
    //         // });
    //       }}
    //     >
    //       {svg.eye()} <span className="f700">VIEW</span>
    //     </button>
    //   ),
    // },
  ]; //$2b$10$z4LBB31bsweAQvaC9ZScCOjPliydIJEbRoqDlMl3kymirnQOAtK7u
  useEffect(() => {
    getVendors(1, 3000, startDate, endDate);
    // eslint-disable-next-line
  }, []);

  const refresh = () => {
    getVendors(1, 100, startDate, endDate, filter);
  };

  return (
    <div>
      <br />
      <br />
      <div className="flex range-picker justify-evenly">
        <Input
          placeholder="From"
          type={"date"}
          style={{ padding: 10, fontSize: 14 }}
          value={startDate}
          onChange={setStartDate}
        />
        <Input
          placeholder="To"
          type={"date"}
          style={{ padding: 10, fontSize: 14 }}
          value={endDate}
          onChange={setEndDate}
        />

        <Select
          placeholder="Filter By"
          initialValue={filter}
          options={filters}
          onSelect={setFilter}
        />
        <Button
          loading={isLoading}
          disabled={isLoading}
          title={"Refresh Vendors"}
          onClick={refresh}
          className={"p-20"}
        />
      </div>
      <div className="flex justify-between align-center gap-3">
        <Card2
          title="Paid Vendors"
          value={vendors.filter((_) => _?.isPaid).length}
        />
        <Card2 title="Pending Vendors" value={vendors.length} />
        <Card2 title="Resolved Vendors" value={0} />
      </div>
      <br />
      <br />

      <Table
        data={vendors.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )}
        head={head}
        title="Vendors"
        btn={{
          title: "View All",
          className: "btn-view",
          onClick: () =>
            navigate(links.vendors.list(), {
              state: { data: vendors },
            }),
        }}
      />
    </div>
  );
}

export default Vendors;
