const getUrlFromBase = (url) => url.replace("/*", "");

export const paths = Object.freeze({
  base: "/",
  login: "/login",
  app: "/app",
  add: "/add",
  append: "/append",
  accounts: "/accounts/*",
  icartsAndLocations: "/icarts-and-locations/*",
  operators: "/operators/*",
  inventory: "/inventory/*",
  wallets: "/wallets/*",
  settings: "/settings/*",
  orders: "/orders/*",
  conceptsAndMenus: "/concepts-and-menus/*",
  analytics: "/analytics/*",
  messages: "/messages/*",
  onlineOrders: "/online-orders/*",
  support: "/support/*",
  configs: "/configs/*",
  list: "/list",
  view: "/view",
  success: "/success",
  businesses: "/businesses/*",
  sales: "/sales/*",
  departments: "/departments/*",
  preOrders: "/pre-orders/*",
  vendors: "/vendors/*",
});

export const links = Object.freeze({
  accounts: {
    to: getUrlFromBase(paths.accounts),
    list: (t) => getUrlFromBase(paths.accounts) + "/list?type=" + t,
    view: (t, id) =>
      getUrlFromBase(paths.accounts) + "/view?type=" + t + "&id=" + id,
    add: (t) => getUrlFromBase(paths.accounts) + "/add?type=" + t,
    success: (t) => getUrlFromBase(paths.accounts) + "/success?type=" + t,
  },

  icartsAndLocations: {
    to: getUrlFromBase(paths.icartsAndLocations),
    list: (t) => getUrlFromBase(paths.icartsAndLocations) + "/list?type=" + t,
    operators: getUrlFromBase(paths.icartsAndLocations) + "/operators",
    view: (t, id) =>
      getUrlFromBase(paths.icartsAndLocations) +
      "/view?type=" +
      t +
      "&id=" +
      id,
    add: (t) => getUrlFromBase(paths.icartsAndLocations) + "/add?type=" + t,
    success: (t) =>
      getUrlFromBase(paths.icartsAndLocations) + "/success?type=" + t,
  },

  conceptsAndMenus: {
    to: getUrlFromBase(paths.conceptsAndMenus),
    list: (t) => getUrlFromBase(paths.conceptsAndMenus) + "/list?type=" + t,
    view: (t, id) =>
      getUrlFromBase(paths.conceptsAndMenus) + "/view?type=" + t + "&id=" + id,
    add: (t) => getUrlFromBase(paths.conceptsAndMenus) + "/add?type=" + t,
    append: (t, id) =>
      getUrlFromBase(paths.conceptsAndMenus) +
      "/append?type=" +
      t +
      "&id=" +
      id,
    success: (t) =>
      getUrlFromBase(paths.conceptsAndMenus) + "/success?type=" + t,
  },

  configs: {
    to: getUrlFromBase(paths.configs),
    list: (t) => getUrlFromBase(paths.configs) + "/list?type=" + t,
    view: (t, id) =>
      getUrlFromBase(paths.configs) + "/view?type=" + t + "&id=" + id,
    add: (t) => getUrlFromBase(paths.configs) + "/add?type=" + t,
    success: (t) => getUrlFromBase(paths.configs) + "/success?type=" + t,
  },

  businesses: {
    to: getUrlFromBase(paths.businesses),
    list: (id) => getUrlFromBase(paths.businesses) + "/list",
    view: (id) => getUrlFromBase(paths.businesses) + "/view?id=" + id,
    add: () => getUrlFromBase(paths.businesses) + "/add",
    success: () => getUrlFromBase(paths.businesses) + "/success",
  },
  preOrders: {
    to: getUrlFromBase(paths.preOrders),
    list: (id) => getUrlFromBase(paths.preOrders) + "/list",
    view: (id) => getUrlFromBase(paths.preOrders) + "/view?id=" + id,
    add: () => getUrlFromBase(paths.preOrders) + "/add",
    success: () => getUrlFromBase(paths.preOrders) + "/success",
  },
  orders: {
    to: getUrlFromBase(paths.orders),
    list: (id) => getUrlFromBase(paths.orders) + "/list",
    view: (id) => getUrlFromBase(paths.orders) + "/view?id=" + id,
    add: () => getUrlFromBase(paths.orders) + "/add",
    success: () => getUrlFromBase(paths.orders) + "/success",
  },
  wallets: {
    to: getUrlFromBase(paths.wallets),
    list: (t) => getUrlFromBase(paths.wallets) + "/list?type=" + t,
    view: (t, id) =>
      getUrlFromBase(paths.wallets) + "/view?type=" + t + "&id=" + id,
    add: (t) => getUrlFromBase(paths.wallets) + "/add?type=" + t,
    append: (t, id) =>
      getUrlFromBase(paths.wallets) + "/append?type=" + t + "&id=" + id,
    success: (t) => getUrlFromBase(paths.wallets) + "/success?type=" + t,
  },
});
