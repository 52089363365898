import { useEffect, useState } from "react";
import useSettings from "../../hooks/api/useSettings";
import { Form, FormInput, Submit } from "../../components/form";
import Loader from "../../components/Loader";
import CheckBox from "../../components/CheckBox";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Select from "../../components/Select";
import useBusinesses from "../../hooks/api/useBusinesses";
import { excludeFromObject } from "../../utils/func";

const Ride = ({
  remove,
  id,
  from,
  riders,
  kiosks,
  to,
  by,
  date,
  handleChange,
}) => {
  return (
    <div className="inputs items-list justify-between">
      <div className="list-itm">
        <div className="input_container">
          <select
            onChange={({ target: { value } }) => {
              const kiosk = kiosks.find((_) => _._id === value);
              handleChange(id, "from", {
                lat: kiosk.location.coords.lat,
                lng: kiosk.location.coords.lng,
                label: kiosk.location.label,
              });
            }}
          >
            <option>From</option>
            {kiosks?.map((k) => (
              <option value={k._id}>{`${k.id} - ${k.location.label}`}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="list-itm">
        <div className="input_container">
          <select
            onChange={({ target: { value } }) => {
              const kiosk = kiosks.find((_) => _._id === value);
              handleChange(id, "to", {
                lat: kiosk.location.coords.lat,
                lng: kiosk.location.coords.lng,
                label: kiosk.location.label,
              });
            }}
          >
            <option>To</option>
            {kiosks?.map((k) => (
              <option value={k._id}>{`${k.id} - ${k.location.label}`}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="list-itm">
        <div className="input_container">
          <select
            onChange={({ target: { value } }) => handleChange(id, "by", value)}
          >
            <option>By</option>
            {riders?.map((r) => (
              <option value={r._id}>{`${r.firstName} ${r.lastName}`}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="list-itm">
        <Input
          value={date}
          placeholder="Date"
          onChange={(e) => handleChange(id, "date", e)}
        />
      </div>
      <Button onClick={remove} title="Dlt" className="btn-delete" />
    </div>
  );
};

const Group = ({
  title,
  Element,
  items,
  partners,
  setItems,
  onSubmit,
  loading,
  ...props
}) => {
  const [partner, setPartner] = useState(null);
  const handleChange = (id, property, value) => {
    const updated = items.map((i) => {
      if (i.id === id) i[property] = value;
      return i;
    });
    setItems(updated);
  };
  return (
    <>
      <h3 className="t-blue">
        {title || (partner && `${partner?.firstName} ${partner?.lastName}`)}
      </h3>
      <br />
      <div className="input_container">
        <select
          onChange={({ target: { value } }) =>
            setPartner(partners.find((_) => _._id === value))
          }
        >
          <option>Partner</option>
          {partners?.map((r) => (
            <option value={r._id}>{`${r.firstName} ${r.lastName}`}</option>
          ))}
        </select>
      </div>
      <br />
      <Button
        style={{ marginLeft: "auto" }}
        width="100px"
        onClick={() =>
          setItems([
            ...items,
            { id: items.length ? items[items.length - 1].id + 1 : 1 },
          ])
        }
        className="btn-100"
        title="ADD"
      />
      <br />
      <div className="list">
        {items.map((i, idx) => (
          <Element
            key={idx}
            {...i}
            {...props}
            handleChange={handleChange}
            remove={() => setItems(items.filter((_) => _.id !== i.id))}
          />
        ))}
      </div>
      <Button
        width="100px"
        onClick={() =>
          onSubmit({
            rides: items.map((_) => excludeFromObject(["id"], _)),
            owner: partner._id,
          })
        }
        loading={loading}
        className="btn-100"
        title="Submit"
      />
    </>
  );
};

function Settings(props) {
  const {
    settings,
    getSettings,
    isLoading,
    updateSettings,
    getPartners,
    getRiders,
    riders,
    payDeliveries,
    partners,
  } = useSettings();
  const [onlineOrderOn, setOnlineOrderOn] = useState(settings?.onlineOrders);
  const { getKiosks, kiosks } = useBusinesses();
  const [isReady, setIsReady] = useState(false);
  const [rides, setRides] = useState([]);

  const refresh = async () => {
    await getSettings();
    await getPartners();
    await getRiders();
    await getKiosks();
    setIsReady(true);
  };

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    setOnlineOrderOn(settings?.onlineOrders);
  }, [settings]);

  if (!isReady) return <Loader loading className="apploader" />;
  return (
    <div>
      <h2>Settings</h2>
      <br />
      <br />
      <Form
        onSubmit={(v) => updateSettings({ ...v, onlineOrders: onlineOrderOn })}
        initialValues={{
          preOrderOnline: settings?.preOrderOnline,
          preOrderOnsite: settings?.preOrderOnsite,
          orderRadius: settings?.orderRadius,
          vendor: settings?.vendor,
        }}
      >
        <div className="inputs">
          <FormInput
            name="preOrderOnline"
            placeholder="Online Pre Order Price"
          />
          <FormInput
            name="preOrderOnsite"
            placeholder="Onsite Pre Order Price"
          />
        </div>
        <div className="inputs">
          <FormInput name="orderRadius" placeholder="Online Order Radius (m)" />
          <FormInput name="vendor" placeholder="Vendor Security Deposite" />
        </div>
        <div className="flex">
          <label>Online Orders</label>
          <CheckBox onChange={setOnlineOrderOn} value={onlineOrderOn} />
          <br />
          <br />
        </div>
        <Submit title="Save" className="btn-submit" loading={isLoading} />
      </Form>
      <br />
      <br />
      <h2>Delivery</h2>
      <br />
      <br />
      <Group
        riders={riders}
        partners={partners}
        items={rides}
        kiosks={kiosks}
        setItems={setRides}
        Element={Ride}
        title=""
        loading={isLoading}
        onSubmit={(d) => {
          payDeliveries(d, () => setRides([]));
        }}
      />
    </div>
  );
}

export default Settings;
