import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useBusinesses from "../../../hooks/api/useBusinesses";

import { Form, Submit, FormSelect, FormInput } from "../../form";
import {
  currencyFormatter,
  formatDate,
  getImageUrl,
} from "../../../utils/func";
import { models, transactionType, walletStatus } from "../../../utils/vars";
import { links } from "../../../utils/routes";
import Table from "../../table/Table";
import svg from "../../../utils/svg";
import Input from "../../Input";

export default function ViewWallet() {
  const {
    state: { data },
  } = useLocation();
  const navigate = useNavigate();
  const {
    isLoading,
    changeWalletStatus,
    getWalletTransactions,
    walletTransactions,
    changeWalletBalance,
  } = useBusinesses();

  useEffect(() => {
    getWalletTransactions(data._id);
  }, []);

  const transactionsHead = [
    {
      title: "#",
      target: "#",
      className: "count",
    },
    {
      title: "Title",
      target: "title",
    },
    {
      title: "Amount",
      target: "amount",
      render: (v) => currencyFormatter(v || 0),
    },
    {
      title: "Status",
      target: "status",
      render: (value) => (
        <span className={`status ${value}`}>{value.toUpperCase()}</span>
      ),
    },
    {
      title: "Date",
      target: "createdAt",
      render: formatDate,
    },
    {
      title: "Actions",
      target: "_id",
      render: (id) => (
        <button
          className="action flex align-center"
          onClick={() =>
            navigate(links.wallets.view(models.transaction, id), {
              state: {
                data: walletTransactions.filter((k) => k._id === id)[0],
              },
            })
          }
        >
          {svg.eye()} <span className="f700">VIEW</span>
        </button>
      ),
    },
  ];

  return (
    <div>
      <h2>View Wallet</h2>
      <br />
      <div className="card-container justify-center">
        <label>Wallet Balance:</label>
        <h1 className="t-blue p-20">{currencyFormatter(data.balance)}</h1>
        <div className="user-profile-head flex align-center">
          <img
            crossOrigin="anonymous"
            src={
              data.account.profileImage
                ? getImageUrl(data.user.profileImage)
                : "https://thumbs.dreamstime.com/z/default-avatar-profile-icon-social-media-user-vector-default-avatar-profile-icon-social-media-user-vector-portrait-176194876.jpg?w=768"
            }
            style={{ height: 100, borderRadius: 50, marginRight: 20 }}
            alt={data.account.firstName}
          />
          <div>
            <h3 className="t-blue">{`${data.account.firstName} ${data.account.lastName} `}</h3>
            <span className="t-grey-3 f500">
              {data.account.email}
              <br />
              {data.account.phoneNumber}
            </span>
            <br />
            <span className={`status ${data.status}`}>
              {data.status == "active" ? "ACTIVE" : data.status.toUpperCase()}
            </span>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="flex forms">
        <div className="f">
          <h2>Wallet Status</h2>
          <Form
            onSubmit={(v) => {
              changeWalletStatus({ status: v.status }, data._id);
            }}
            initialValues={{
              balance: data.balance,
              status: data.status,
            }}
          >
            <br />
            <div className="inputs">
              <FormSelect
                name={"status"}
                options={Object.values(walletStatus).map((t) => ({
                  value: t,
                  label: t.toUpperCase(),
                }))}
                initialValue={data.status}
                placeholder="Select Status"
              />
            </div>
            <div className="flex">
              {/* <label>Active</label> */}
              {/* <CheckBox onChange={setIsActive} value={isActive} /> */}
              {/* <br /> */}
              {/* <br /> */}
            </div>
            <Submit
              loading={isLoading}
              disabled={isLoading}
              className="btn-submit"
              title="Save Status"
            />
          </Form>
        </div>
        <div className="f">
          <h2>Create Transaction</h2>
          <Form
            onSubmit={(v) =>
              changeWalletBalance({
                title: v.title,
                amount:
                  v.type === transactionType.debit ? 0 - v.amount : v.amount,
                account: data.account._id,
              })
            }
            initialValues={{
              amount: null,
              type: null,
              description: "",
            }}
          >
            <br />
            <FormInput name="title" placeholder="Transaction title" />
            <br />
            <FormInput
              containerStyle={{ height: 200 }}
              type="description"
              name="description"
              placeholder="Description"
            />
            <br />
            <div className="inputs">
              <FormInput type="number" name="amount" placeholder="Amount" />
              <FormSelect
                name={"type"}
                options={Object.values(transactionType).map((t) => ({
                  value: t,
                  label: t.toUpperCase(),
                }))}
                placeholder="Transaction type"
              />
            </div>
            <div className="flex">
              {/* <label>Active</label> */}
              {/* <CheckBox onChange={setIsActive} value={isActive} /> */}
              {/* <br /> */}
              {/* <br /> */}
            </div>
            <Submit
              loading={isLoading}
              disabled={isLoading}
              className="btn-submit"
              title="Confirm"
            />
          </Form>
        </div>
      </div>
      <br />
      <br />
      <Table
        title="Transactions"
        btn={{
          title: "View all",
          className: "btn-view",
          onClick: () =>
            navigate(links.wallets.list(models.transaction), {
              state: { data: walletTransactions },
            }),
        }}
        head={transactionsHead}
        data={walletTransactions.slice(0, 10)}
      />
    </div>
  );
}
